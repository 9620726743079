var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "activity-features-grid" },
    [
      _c("activity-icon", {
        attrs: {
          "icon-name": "TourDuration",
          title: _vm.getTourDurationTitle,
          description: _vm.getTourDurationDescription,
        },
      }),
      _vm._v(" "),
      _c("activity-icon", {
        attrs: {
          "icon-name": "TourVouchers",
          title: _vm.translations.textVouchers.text,
          description: _vm.getTourVouchersDescription,
        },
      }),
      _vm._v(" "),
      _vm.tour.covid_requirements.length || _vm.tour.covid_security.length
        ? _c("activity-icon", {
            attrs: {
              "icon-name": "Covid",
              title: _vm.translations.textCovidP.text,
              description: _vm.translations.textHealth.text,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tour.max_schedule_travelers < 9
        ? _c("activity-icon", {
            attrs: {
              "icon-name": "TourSmall",
              title: _vm.translations.textSmallGroup.text,
              description: _vm.translations.textLimited.text.replace(
                "8",
                _vm.tour.max_schedule_travelers
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tour.languages.length !== 0
        ? _c("activity-icon", {
            attrs: {
              "icon-name": "TourGuide",
              title: _vm.translations.textTourGuide.text,
              languages: _vm.tour.languages,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tour.cancellation_type !== "no_refund"
        ? _c("activity-icon", {
            attrs: {
              "icon-name": "TourCancellation",
              title: _vm.translations.textCancellation.text,
              description: _vm.translations.textBeforeStart.text,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tour.pick_ups.length !== 0
        ? _c("activity-icon", {
            attrs: {
              "icon-name": "TourTransfer",
              title: _vm.translations.textWithTransfer.text,
              description: _vm.translations.textPickUp.text,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tour.is_skip_line
        ? _c("activity-icon", {
            attrs: {
              "icon-name": "TourSkipLine",
              title: _vm.translations.textSTL.text,
              description: _vm.translations.descriptionAcitivity.text,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }