var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.clearSearch,
          expression: "clearSearch",
        },
      ],
      staticClass: "categories-form-tags",
    },
    [
      _c(
        "div",
        {
          staticClass: "categories-form-tags__subcategory-select",
          on: { click: _vm.clearSubcategory },
        },
        [
          _vm.selectedSubcategory
            ? _c("span", [_vm._v(_vm._s(_vm.selectedSubcategory.title))])
            : _c(
                "span",
                {
                  staticClass: "categories-form-tags__subcategory-placeholder",
                },
                [_vm._v("Select one category")]
              ),
          _vm._v(" "),
          _c("img", {
            attrs: {
              src: require("@/assets/img/svg/drop-down-arrow.svg"),
              alt: "drop",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "categories-form-tags__selected-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "categories-form-tags__selected",
            class: { "categories-form-tags__selected--error": _vm.error },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchString,
                  expression: "searchString",
                },
              ],
              staticClass: "categories-form-tags__search",
              attrs: {
                type: "text",
                placeholder: _vm.translations.tagsPlaceholder.text,
              },
              domProps: { value: _vm.searchString },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchString = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _vm._l(_vm.localTags, function (tag) {
              return _c(
                "span",
                {
                  key: tag.id,
                  staticClass: "categories-form-tags__selected-item",
                },
                [
                  _vm._v("\n        " + _vm._s(tag.title) + "\n        "),
                  _c(
                    "button",
                    {
                      staticClass: "categories-form-tags__selected-delete",
                      on: {
                        click: function ($event) {
                          return _vm.deleteTag(tag.id)
                        },
                      },
                    },
                    [
                      _c("BaseIcon", {
                        staticClass:
                          "categories-form-tags__selected-delete-icon",
                        attrs: { "icon-name": "Close" },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.selectedSubcategory || _vm.searchString.length > 0
        ? _c(
            "div",
            { staticClass: "categories-form-tags__list" },
            _vm._l(_vm.filteredTags, function (subcategoryTag) {
              return _c(
                "div",
                {
                  key: subcategoryTag.id,
                  staticClass: "categories-form-tags__subcategory",
                },
                [
                  _c("VCheckbox", {
                    staticClass: "categories-form-tags__tag",
                    attrs: {
                      value: subcategoryTag,
                      label: subcategoryTag.title,
                    },
                    model: {
                      value: _vm.localTags,
                      callback: function ($$v) {
                        _vm.localTags = $$v
                      },
                      expression: "localTags",
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _c(
            "div",
            { staticClass: "categories-form-tags__list" },
            _vm._l(_vm.subcategories, function (subcategory) {
              return _c(
                "div",
                {
                  key: subcategory.id,
                  staticClass: "categories-form-tags__subcategory",
                  on: {
                    click: function ($event) {
                      return _vm.selectSubcategory(subcategory)
                    },
                  },
                },
                [
                  _vm._v("\n      " + _vm._s(subcategory.title) + " "),
                  _c("img", {
                    staticClass: "arrow-right",
                    attrs: {
                      src: require("@/assets/img/svg/arrow-right.svg"),
                      alt: "arrow-right",
                    },
                  }),
                ]
              )
            }),
            0
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }