<template>
  <div v-if="pickUps.length !== 0" class="preview__section">
    <div class="preview__title">
      {{ translations.textPickupPoint.text }}
    </div>
    <span class="preview__text">{{ pickUps[0].address }}</span>
    <br v-if="pickUpDescription">
    <span v-if="pickUpDescription" class="preview__text">{{ pickUpDescription }}</span>
  </div>
</template>

<script>
export default {
  name: 'PickUp',
  props: {
    pickUps: { type: Array, default: () => [] },
    pickUpDescription: { type: String, default: '' },
    translations: { type: Object, default: () => ({}) }
  }
}
</script>

<style lang="scss">
</style>
