<template>
  <div class="information-on-voucher-page">
    <div v-if="loadingPage">
      <local-loader />
      <div class="information-on-voucher-page__loading-title">Loading pricing type...</div>
    </div>
    <div v-else>
      <div class="information-on-voucher-page__main-title">{{ translations.title.text }}</div>
      <VFiled
        class="information-on-voucher-page__field"
        :label="translations.labelImportantInformation.text"
        :description="translations.descriptionImportantInformation.text"
      >
        <VTextarea
          v-model="importantInfo"
          :max-length="400"
          :placeholder="translations.placeholderImportantInformation.text"
          rows="4"
        />
      </VFiled>
      <VFiled
        class="information-on-voucher-page__field"
        :label="translations.labelAdditionlInformation.text"
        :description="translations.descriptionImportantInformation.text"
      >
        <template #description
          >{{ translations.descriptionAdditionlInformation.text }}
          <b>{{ translations.descriptionBeforeYouGo.text }}</b></template
        >
        <VTextarea
          v-model="leaving"
          :max-length="400"
          :placeholder="translations.placeholderAdditionlInformation.text"
          rows="4"
        />
      </VFiled>
      <VBtn base :name="translations.buttonSave.text" :loading="loading" class="mt-15" @click="save" />
    </div>
  </div>
</template>
<script>
import VFiled from '@/components/controls/VField.vue';
import VTextarea from '@/components/controls/VTextarea.vue';
import VBtn from '@/components/controls/VBtn.vue';
import LocalLoader from '@/components/common/LocalLoader';

export default {
  components: {
    VTextarea,
    VBtn,
    VFiled,
    LocalLoader,
  },
  props: {
    tour: { type: Object, default: () => ({}) },
    lang: { type: String, default: '' },
    isBuild: { type: Boolean, default: false },
  },
  data() {
    return {
      loadingPage: false,
      loading: false,
      leaving: '',
      importantInfo: '',
      translations: {},
    };
  },
  async fetch() {
    this.loadingPage = true;
    const translationsPage = await this.$axios.$get(`/api/v1/pages/lk-information-on-voucher?language=${this.lang}`);
    this.translations = translationsPage.fields;
    this.leaving = this.tour.tour_leaving || '';
    this.importantInfo = this.tour.tour_important_info || '';
    this.loadingPage = false;
  },
  methods: {
    async save() {
      this.loading = true;
      const data = { tour_leaving: this.leaving, tour_important_info: this.importantInfo };
      await this.$axios.$put(`/api/v1/edit-tour/`, data, {
        params: {
          tour_id: this.tour.id,
        },
      });
      if (this.isBuild) {
        const productStep = new FormData();
        productStep.append('information_voucher', true);
        await this.$axios.$put(`api/v1/set-tour-creation-steps/${this.tour.id}`, productStep);
      }

      this.loading = false;
      this.$router.push(
        this.localePath(
          this.isBuild
            ? `/product/${this.tour.slug}/build/booking-and-tickets/cancellation-policy`
            : `/product/${this.tour.slug}/tickets`
        )
      );
    },
  },
};
</script>

<style lang="scss">
.information-on-voucher-page {
  &__main-title {
    margin-bottom: 48px;
    font-weight: 700;
    font-size: 29px;
    word-wrap: break-word;
  }

  &__field {
    max-width: 750px;
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
  &__loading-title {
    text-align: center;
    margin-top: 30px;
  }
}
</style>
