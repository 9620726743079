var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tourTo != null && _vm.tourFrom != null
    ? _c(
        "div",
        [
          _c("div", { staticClass: "translation-block" }, [
            _c("div", { staticClass: "translation-block__title" }, [
              _vm._v("First impression"),
            ]),
            _vm._v(" "),
            _vm.tour.short_description != null &&
            _vm.tour.short_description != ""
              ? _c(
                  "div",
                  {
                    staticClass: "translation-row translation-block__container",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "translation-row__english" },
                      [
                        _c("div", [_vm._v("Short description")]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { disabled: "", rows: "2" },
                          model: {
                            value: _vm.tourFrom.short_description,
                            callback: function ($$v) {
                              _vm.$set(_vm.tourFrom, "short_description", $$v)
                            },
                            expression: "tourFrom.short_description",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "translation-row__new-lang" },
                      [
                        _c("div", [_vm._v("Short description")]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: {
                            error: _vm.$v.tourTo.short_description.$error,
                            "max-length": 250,
                            rows: "2",
                          },
                          model: {
                            value: _vm.$v.tourTo.short_description.$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.tourTo.short_description,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.tourTo.short_description.$model",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.tour.important_info != null && _vm.tour.important_info != ""
            ? _c("div", { staticClass: "translation-block" }, [
                _c("div", { staticClass: "translation-block__title" }, [
                  _vm._v("Information travelers need from you"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "translation-row translation-block__container",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "translation-row__english" },
                      [
                        _c("div", [_vm._v("Please note")]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { disabled: "", rows: "2" },
                          model: {
                            value: _vm.tourFrom.important_info,
                            callback: function ($$v) {
                              _vm.$set(_vm.tourFrom, "important_info", $$v)
                            },
                            expression: "tourFrom.important_info",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "translation-row__new-lang" },
                      [
                        _c("div", [_vm._v("Please note")]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: {
                            error: _vm.$v.tourTo.important_info.$error,
                            "max-length": 250,
                            rows: "2",
                          },
                          model: {
                            value: _vm.$v.tourTo.important_info.$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.tourTo.important_info,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.tourTo.important_info.$model",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "translation-block" },
            [
              _c("div", { staticClass: "translation-block__title" }, [
                _vm._v(_vm._s(_vm.translations.activityDetails.text)),
              ]),
              _vm._v(" "),
              _vm.tour.description != null && _vm.tour.description != ""
                ? _c(
                    "div",
                    {
                      staticClass:
                        "translation-row translation-block__container",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "translation-row__english" },
                        [
                          _c("div", [_vm._v("Full description")]),
                          _vm._v(" "),
                          _c("VTextarea", {
                            attrs: { disabled: "", rows: "6" },
                            model: {
                              value: _vm.tourFrom.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.tourFrom, "description", $$v)
                              },
                              expression: "tourFrom.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "translation-row__new-lang" },
                        [
                          _c("div", [_vm._v("Full description")]),
                          _vm._v(" "),
                          _c("VTextarea", {
                            attrs: {
                              error: _vm.$v.tourTo.description.$error,
                              "max-length": 1000,
                              rows: "6",
                            },
                            model: {
                              value: _vm.$v.tourTo.description.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.tourTo.description,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.tourTo.description.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.tour.additional_info, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "tour-additional-info" + index,
                    staticClass: "translation-row translation-block__container",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "translation-row__english" },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.translations.additionalInformation.text)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { disabled: "", rows: "2" },
                          model: {
                            value: _vm.tourFrom.additional_info[index].content,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tourFrom.additional_info[index],
                                "content",
                                $$v
                              )
                            },
                            expression:
                              "tourFrom.additional_info[index].content",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "translation-row__new-lang" },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.translations.additionalInformation.text)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { "max-length": 250, rows: "2" },
                          model: {
                            value: _vm.tourTo.additional_info[index].content,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tourTo.additional_info[index],
                                "content",
                                $$v
                              )
                            },
                            expression: "tourTo.additional_info[index].content",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.tour.get_tour_highlights, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "tour-highlights" + index,
                    staticClass: "translation-row translation-block__container",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "translation-row__english" },
                      [
                        _c("div", [
                          _vm._v(_vm._s(_vm.translations.highlights.text)),
                        ]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { disabled: "", rows: "2" },
                          model: {
                            value:
                              _vm.tourFrom.get_tour_highlights[index]
                                .description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tourFrom.get_tour_highlights[index],
                                "description",
                                $$v
                              )
                            },
                            expression:
                              "tourFrom.get_tour_highlights[index].description",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "translation-row__new-lang" },
                      [
                        _c("div", [
                          _vm._v(_vm._s(_vm.translations.highlights.text)),
                        ]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { "max-length": 250, rows: "2" },
                          model: {
                            value:
                              _vm.tourTo.get_tour_highlights[index].description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tourTo.get_tour_highlights[index],
                                "description",
                                $$v
                              )
                            },
                            expression:
                              "tourTo.get_tour_highlights[index].description",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.tour.get_tour_attraction.length > 0
            ? _c(
                "div",
                { staticClass: "translation-block" },
                [
                  _c("div", { staticClass: "translation-block__title" }, [
                    _vm._v("Information about the ticket"),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.tour.get_tour_attraction,
                    function (attraction, index) {
                      return _c(
                        "div",
                        {
                          key: "tour-attraction" + index,
                          staticClass:
                            "translation-row translation-block__container",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "translation-row__english" },
                            [
                              _c("div", [_vm._v("Attractions included")]),
                              _vm._v(" "),
                              _c("VTextarea", {
                                attrs: { disabled: "", rows: "4" },
                                model: {
                                  value:
                                    _vm.tourFrom.get_tour_attraction[index]
                                      .description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.tourFrom.get_tour_attraction[index],
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "tourFrom.get_tour_attraction[index].description",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "translation-row__new-lang" },
                            [
                              _c("div", [_vm._v("Attractions included")]),
                              _vm._v(" "),
                              _c("VTextarea", {
                                attrs: { "max-length": 1000, rows: "4" },
                                model: {
                                  value:
                                    _vm.tourTo.get_tour_attraction[index]
                                      .description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.tourTo.get_tour_attraction[index],
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "tourTo.get_tour_attraction[index].description",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "translation-block" },
            [
              _vm.tourIncludes.length > 0
                ? _c("div", { staticClass: "translation-block__title" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.translations.inclusionsExclusions.text) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.tourIncludes, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "tour-yncludes" + index,
                    staticClass: "translation-row translation-block__container",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "translation-row__english" },
                      [
                        _c("div", [
                          _vm._v(_vm._s(_vm.translations.inclusions.text)),
                        ]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { disabled: "", rows: "2" },
                          model: {
                            value: _vm.tourIncludes[index].description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tourIncludes[index],
                                "description",
                                $$v
                              )
                            },
                            expression: "tourIncludes[index].description",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "translation-row__new-lang" },
                      [
                        _c("div", [
                          _vm._v(_vm._s(_vm.translations.inclusions.text)),
                        ]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { rows: "2", "max-length": 250 },
                          model: {
                            value: _vm.tourIncludesTranslate[index].description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tourIncludesTranslate[index],
                                "description",
                                $$v
                              )
                            },
                            expression:
                              "tourIncludesTranslate[index].description",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.tourExcludes, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "tour-excludes" + index,
                    staticClass: "translation-row translation-block__container",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "translation-row__english" },
                      [
                        _c("div", [
                          _vm._v(_vm._s(_vm.translations.exclusions.text)),
                        ]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { disabled: "", rows: "2" },
                          model: {
                            value: _vm.tourExcludes[index].description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tourExcludes[index],
                                "description",
                                $$v
                              )
                            },
                            expression: "tourExcludes[index].description",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "translation-row__new-lang" },
                      [
                        _c("div", [
                          _vm._v(_vm._s(_vm.translations.exclusions.text)),
                        ]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { rows: "2", "max-length": 250 },
                          model: {
                            value: _vm.tourExcludesTranslate[index].description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tourExcludesTranslate[index],
                                "description",
                                $$v
                              )
                            },
                            expression:
                              "tourExcludesTranslate[index].description",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("v-btn", {
            attrs: {
              base: "",
              name: "Save & continue",
              loading: _vm.loading,
              "loading-name": "Saved..",
            },
            on: { click: _vm.saveTranslate },
          }),
          _vm._v(" "),
          _vm.loading ? _c("loader") : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }