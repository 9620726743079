<template>
  <div class="product-nav">
    <div class="product-nav__complete-percent">
      <div class="title">{{ completePercent }}% Complete</div>
      <div class="line">
        <div class="line_active" :style="`width: ${completePercent}%;`" />
      </div>
    </div>
    <div class="product-nav__basic product-nav__info-type">
      <div
        class="title"
        :class="{ title_active: basicInfo.is_open, disabled: !basicInfo.is_open }"
        @click="basicInfo.is_hiden = !basicInfo.is_hiden"
      >
        {{ translations.productStepBasic.text }}
      </div>
      <div v-if="basicInfo.is_open && !basicInfo.is_hiden" class="steps">
        <nuxt-link
          v-if="!slug"
          :to="localePath(`/product/build/basic/product-setup`)"
          :class="{ disabled: !basicInfo.product_setup_open }"
          class="steps__item"
        >
          {{ translations.productStepProductSetup.text }}
        </nuxt-link>
        <nuxt-link
          v-else
          :to="localePath(`/product/${slug}/build/basic/product-setup`)"
          :class="{ disabled: !basicInfo.product_setup_open }"
          class="steps__item"
        >
          {{ translations.productStepProductSetup.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/basic/categories-and-themes`)"
          :class="{ disabled: !basicInfo.categories_themes_open }"
          class="steps__item"
        >
          {{ translations.productStepCategoriesThemes.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/basic/photos`)"
          :class="{ disabled: !basicInfo.photos_open }"
          class="steps__item"
        >
          {{ translations.productStepPhotos.text }}
        </nuxt-link>
      </div>
    </div>
    <div class="product-nav__product-content product-nav__info-type">
      <div
        class="title"
        :class="{ title_active: productContentInfo.is_open, disabled: !productContentInfo.is_open }"
        @click="productContentInfo.is_hiden = !productContentInfo.is_hiden"
      >
        {{ translations.productStepProductContent.text }}
      </div>
      <div v-if="productContentInfo.is_open && !productContentInfo.is_hiden" class="steps">
        <nuxt-link
          :to="localePath(`/product/${slug}/build/product-content/meeting-and-pickup`)"
          :class="{ disabled: !productContentInfo.meeting_pickup_open }"
          class="steps__item"
        >
          {{ translations.productStepMeetingPickup.text }}
        </nuxt-link>
        <nuxt-link
          v-if="!isTravelServices"
          :to="localePath(`/product/${slug}/build/product-content/information-about-ticket`)"
          :class="{ disabled: !productContentInfo.information_about_ticket_open }"
          class="steps__item"
        >
          {{ translations.productStepInformationAboutTicket.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/product-content/makes-unique`)"
          :class="{ disabled: !productContentInfo.activity_unique_open }"
          class="steps__item"
        >
          {{ isTravelServices ? 'About your service' : translations.productStepFirstImpression.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/product-content/activities-details`)"
          :class="{ disabled: !productContentInfo.activities_details_open }"
          class="steps__item"
        >
          {{ isTravelServices ? 'Transportation details' : translations.productStepActivitiesDetails.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/product-content/inclusions-and-exclusions`)"
          :class="{ disabled: !productContentInfo.inclusions_exclusions_open }"
          class="steps__item"
        >
          {{ translations.productStepInclusionsExclusions.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/product-content/guides-and-languages`)"
          :class="{ disabled: !productContentInfo.guides_languages_open }"
          class="steps__item"
        >
          {{ translations.productStepGuidesLanguages.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/product-content/information-edit`)"
          :class="{ disabled: !productContentInfo.information_travellers_open }"
          class="steps__item"
        >
          {{ translations.productStepInformationTravellersNeed.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/product-content/covid`)"
          :class="{ disabled: !productContentInfo.covid_open }"
          class="steps__item"
        >
          {{ translations.productStepCovid.text }}
        </nuxt-link>
      </div>
    </div>
    <div class="product-nav__schedules-pricing product-nav__info-type">
      <div
        class="title"
        :class="{ title_active: schedulesContentInfo.is_open, disabled: !schedulesContentInfo.is_open }"
        @click="schedulesContentInfo.is_hiden = !schedulesContentInfo.is_hiden"
      >
        {{ translations.productStepSchedulesPricing.text }}
      </div>
      <div v-if="schedulesContentInfo.is_open && !schedulesContentInfo.is_hiden" class="steps">
        <nuxt-link
          :to="localePath(`/product/${slug}/build/schedules-and-pricing/pricing-type`)"
          :class="{ disabled: !schedulesContentInfo.product_type_open }"
          class="steps__item"
        >
          {{ translations.productStepPricingType.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/schedules-and-pricing/schedules`)"
          :class="{ disabled: !schedulesContentInfo.schedules_open }"
          class="steps__item"
        >
          {{ translations.productStepSchedules.text }}
        </nuxt-link>
      </div>
    </div>
    <div class="product-nav__booking-tickets product-nav__info-type">
      <div
        class="title"
        :class="{
          title_active: bookingTicketInfo.is_open,
          disabled: !bookingTicketInfo.is_open,
        }"
        @click="bookingTicketInfo.is_hiden = !bookingTicketInfo.is_hiden"
      >
        Booking & Tickets
      </div>
      <div v-if="bookingTicketInfo.is_open && !bookingTicketInfo.is_hiden" class="steps">
        <nuxt-link
          :to="localePath(`/product/${slug}/build/booking-and-tickets/booking-process`)"
          :class="{ disabled: !bookingTicketInfo.booking_process_open }"
          class="steps__item"
        >
          {{ translations.productStepBookingProcess.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/booking-and-tickets/information-on-voucher`)"
          :class="{ disabled: !bookingTicketInfo.information_voucher }"
          class="steps__item"
        >
          {{ translations.productStepVoucher.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/booking-and-tickets/cancellation-policy`)"
          :class="{ disabled: !bookingTicketInfo.cancellation_policy_open }"
          class="steps__item"
        >
          {{ translations.productStepCancellationPolicy.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/booking-and-tickets/information-edit`)"
          :class="{ disabled: !bookingTicketInfo.traveler_required_open }"
          class="steps__item"
        >
          {{ translations.productStepTravelerRequired.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/booking-and-tickets/ticket-details`)"
          :class="{ disabled: !bookingTicketInfo.tickets_details_open }"
          class="steps__item"
        >
          {{ translations.productStepTicketsDetails.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/booking-and-tickets/ticket-redemption`)"
          :class="{ disabled: !bookingTicketInfo.tickets_redemption_open }"
          class="steps__item"
        >
          {{ translations.productStepTicketsRedemption.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/booking-and-tickets/ticket-preview`)"
          :class="{ disabled: !bookingTicketInfo.tickets_preview_open }"
          class="steps__item"
        >
          {{ translations.productStepTicketsPreview.text }}
        </nuxt-link>
      </div>
    </div>
    <div class="product-nav__finish product-nav__info-type">
      <div
        class="title"
        :class="{ title_active: finishInfo.is_open, disabled: !finishInfo.is_open }"
        @click="finishInfo.is_hiden = !finishInfo.is_hiden"
      >
        {{ translations.productStepFinish.text }}
      </div>
      <div v-if="finishInfo.is_open && !finishInfo.is_hiden" class="steps">
        <nuxt-link
          :to="localePath(`/product/${slug}/build/finish/special-offers`)"
          :class="{ disabled: !finishInfo.special_offers_open }"
          class="steps__item"
        >
          {{ translations.productSpecialOffers.text }}
        </nuxt-link>
        <nuxt-link
          :to="localePath(`/product/${slug}/build/finish/submit-for-review`)"
          :class="{ disabled: !finishInfo.submit_review_open }"
          class="steps__item"
        >
          {{ translations.productSubmitReview.text }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    tour: { type: Object, default: () => {} },
  },
  data: () => ({
    slug: '',
    basicInfo: {
      is_hiden: false,
      is_open: true,
      product_setup_open: true,
      categories_themes_open: false,
      photos_open: false,
    },
    productContentInfo: {
      is_hiden: true,
      is_open: false,
      meeting_pickup_open: false,
      information_about_ticket: false,
      activity_unique_open: false,
      activities_details_open: false,
      inclusions_exclusions_open: false,
      guides_languages_open: false,
      information_travellers_open: false,
      covid_open: false,
    },
    schedulesContentInfo: {
      is_hiden: true,
      is_open: false,
      product_type_open: false,
      schedules_open: false,
    },
    bookingTicketInfo: {
      is_hiden: true,
      is_open: false,
      booking_process_open: false,
      cancellation_policy_open: false,
      traveler_required_open: false,
      tickets_details_open: false,
      tickets_redemption_open: false,
      tickets_preview_open: false,
      information_voucher: false,
    },
    finishInfo: {
      is_hiden: true,
      is_open: false,
      special_offers_open: false,
      submit_review_open: false,
    },
    completePercent: 0,
    steps: {},
  }),
  async fetch() {
    // step 5.555 % (5-6 %)
    if (this.tour !== undefined) {
      this.slug = this.tour.slug;
      this.steps = await this.$axios.$get(`/api/v1/get-tour-creation-steps/${this.tour.id}`);
      // Basic info
      if (this.steps.product_setup) {
        this.completePercent += 6;
        this.basicInfo.categories_themes_open = true;
      }
      if (this.steps.categories_themes) {
        this.completePercent += 6;
        this.basicInfo.photos_open = true;
      }
      if (this.steps.photos) {
        this.completePercent += 5;
        this.basicInfo.is_hiden = true;
        this.productContentInfo.is_open = true;
        this.productContentInfo.is_hiden = false;
        this.productContentInfo.meeting_pickup_open = true;
      }

      // Product content info
      if (this.steps.meeting_pickup) {
        this.completePercent += 5;
        this.productContentInfo.information_about_ticket_open = true;
      }
      if (this.steps.information_about_ticket) {
        this.completePercent += 2;
        this.productContentInfo.activity_unique_open = true;
      }
      if (this.steps.activity_unique) {
        this.completePercent += 5;
        this.productContentInfo.activities_details_open = true;
      }
      if (this.steps.activity_details) {
        this.completePercent += 5;
        this.productContentInfo.inclusions_exclusions_open = true;
      }
      if (this.steps.inclusion_exclusions) {
        this.completePercent += 5;
        this.productContentInfo.guides_languages_open = true;
      }
      if (this.steps.guides_languages) {
        this.completePercent += 5;
        this.productContentInfo.information_travellers_open = true;
      }
      if (this.steps.information_from_you) {
        this.completePercent += 6;
        this.productContentInfo.covid_open = true;
      }
      if (this.steps.covid) {
        this.completePercent += 5;
        this.productContentInfo.is_hiden = true;
        this.schedulesContentInfo.is_open = true;
        this.schedulesContentInfo.is_hiden = false;
        this.schedulesContentInfo.product_type_open = true;
      }

      // Schedules content info
      if (this.steps.pricing_type) {
        this.completePercent += 5;
        this.schedulesContentInfo.schedules_open = true;
      }
      if (this.steps.schedules) {
        this.completePercent += 8;
        this.schedulesContentInfo.is_hiden = true;
        this.bookingTicketInfo.is_open = true;
        this.bookingTicketInfo.is_hiden = false;
        this.bookingTicketInfo.booking_process_open = true;
      }

      // Booking ticket info
      if (this.steps.booking_process) {
        this.completePercent += 5;
        this.bookingTicketInfo.information_voucher = true;
      }
      if (this.steps.information_voucher) {
        this.completePercent += 2;
        this.bookingTicketInfo.cancellation_policy_open = true;
      }
      if (this.steps.cancellation_policy) {
        this.completePercent += 3;
        this.bookingTicketInfo.traveler_required_open = true;
      }
      if (this.steps.information_from_travelers) {
        this.completePercent += 5;
        this.bookingTicketInfo.tickets_details_open = true;
      }
      if (this.steps.tickets_details) {
        this.completePercent += 2;
        this.bookingTicketInfo.tickets_redemption_open = true;
      }
      if (this.steps.ticket_redemption) {
        this.completePercent += 3;
        this.bookingTicketInfo.tickets_preview_open = true;
      }
      if (this.steps.tickets_preview) {
        this.completePercent += 5;
        this.bookingTicketInfo.is_hiden = true;
        this.finishInfo.is_open = true;
        this.finishInfo.is_hiden = false;
        this.finishInfo.special_offers_open = true;
      }

      // Finish info
      if (this.steps.special_offer) {
        this.completePercent += 6;
        this.finishInfo.submit_review_open = true;
      }
    }

    // #region open current category
    if (
      this.$nuxt.$route.path === `/product/${this.slug}/build/basic/product-setup` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/basic/categories-and-themes` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/basic/photos`
    ) {
      this.basicInfo.is_hiden = false;
      this.productContentInfo.is_hiden = true;
      this.schedulesContentInfo.is_hiden = true;
      this.bookingTicketInfo.is_hiden = true;
      this.finishInfo.is_hiden = true;
    } else if (
      this.$nuxt.$route.path === `/product/${this.slug}/build/product-content/meeting-and-pickup` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/product-content/makes-unique` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/product-content/activities-details` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/product-content/inclusions-and-exclusions` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/product-content/guides-and-languages` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/product-content/information-edit` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/product-content/covid` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/product-content/information-about-ticket`
    ) {
      this.productContentInfo.is_hiden = false;
      this.basicInfo.is_hiden = true;
      this.schedulesContentInfo.is_hiden = true;
      this.bookingTicketInfo.is_hiden = true;
      this.finishInfo.is_hiden = true;
    } else if (
      this.$nuxt.$route.path === `/product/${this.slug}/build/schedules-and-pricing/pricing-type` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/schedules-and-pricing/schedules`
    ) {
      this.schedulesContentInfo.is_hiden = false;
      this.basicInfo.is_hiden = true;
      this.productContentInfo.is_hiden = true;
      this.bookingTicketInfo.is_hiden = true;
      this.finishInfo.is_hiden = true;
    } else if (
      this.$nuxt.$route.path === `/product/${this.slug}/build/booking-and-tickets/booking-process` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/booking-and-tickets/cancellation-policy` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/booking-and-tickets/information-edit` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/booking-and-tickets/ticket-details` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/booking-and-tickets/ticket-redemption` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/booking-and-tickets/ticket-preview`
    ) {
      this.bookingTicketInfo.is_hiden = false;
      this.basicInfo.is_hiden = true;
      this.productContentInfo.is_hiden = true;
      this.schedulesContentInfo.is_hiden = true;
      this.finishInfo.is_hiden = true;
    } else if (
      this.$nuxt.$route.path === `/product/${this.slug}/build/finish/special-offers` ||
      this.$nuxt.$route.path === `/product/${this.slug}/build/finish/submit-for-review`
    ) {
      this.finishInfo.is_hiden = false;
      this.basicInfo.is_hiden = true;
      this.productContentInfo.is_hiden = true;
      this.schedulesContentInfo.is_hiden = true;
      this.bookingTicketInfo.is_hiden = true;
    }
    // #endregion open current category
  },

  computed: {
    ...mapState({
      translations: 'headerTranslate',
    }),

    isTravelServices() {
      return this.tour.category.slug === 'travel-services';
    },
  },
};
</script>

<style lang="scss">
.product-nav {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 45px;
  left: 0;
  background-color: hsl(0, 0%, 100%);
  width: 265px;
  border-right: 1px solid hsl(0, 0%, 85%);
  overflow-x: hidden;
  padding-bottom: 100px;
  &__info-type {
    border-bottom: 1px solid hsl(0, 0%, 85%);
    .title {
      cursor: pointer;
      padding: 20px 20px 20px 20px;
      font-size: 18px;
      font-weight: 700;
      &.disabled {
        color: $gray-two;
        pointer-events: none;
      }
    }
    .steps {
      &__item {
        padding: 15px 30px 15px 30px;
        display: block;
        font-size: 16px;
        line-height: 16px;
        &:first-child {
          padding: 10px 20px 20px 30px;
        }
        &:hover {
          color: $primary;
        }
        &.nuxt-link-active {
          padding: 15px 30px 15px 30px;
          background-color: hsl(0, 0%, 95%);
          position: relative;
          font-weight: bold;
        }
        &.disabled {
          color: $gray-two;
          pointer-events: none;
        }
      }
    }
  }
  &__complete-percent {
    border-bottom: 1px solid hsl(0, 0%, 85%);
    .title {
      padding: 20px 20px 0 20px;
      font-size: 18px;
      font-weight: 700;
    }
    .line {
      position: relative;
      width: 85%;
      height: 12px;
      border-radius: 10px;
      margin: 5px 20px 20px 20px;
      background: #e5e7ec;
      &_active {
        position: absolute;
        height: 12px;
        background: $primary;
        border-radius: 10px;
      }
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
