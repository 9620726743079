var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "preview__section", attrs: { id: "full-description" } },
    [
      _c("div", { staticClass: "preview__title" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.translations.textDescription.text) + "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "preview__text", attrs: { id: `CTFullDescription` } },
        [
          _c(
            "span",
            {
              staticClass: "preview__text",
              class: { "preview__text--desc": !_vm.showFullDescription },
              attrs: { id: `CTFullDescriptionRows` },
            },
            [_vm._v(_vm._s(_vm.description))]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isDescriptionNotFull
        ? [
            !_vm.showFullDescription
              ? _c(
                  "span",
                  {
                    staticClass: "preview__read-more",
                    on: {
                      click: function ($event) {
                        _vm.showFullDescription = true
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.translations.textReadMore.text))]
                )
              : _c(
                  "span",
                  {
                    staticClass: "preview__read-more",
                    on: {
                      click: function ($event) {
                        _vm.showFullDescription = false
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.translations.textHide.text))]
                ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }