<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-setup-password">
      <div class="pop-up-setup-password__content">
        <div class="title">
          {{ headerTranslate.dearSupplier.text }}
        </div>
        <div class="subtitle">
          {{ headerTranslate.pleaseCreatePasswordBefore.text }}
        </div>
        <!-- <div class="lang" @click="langMenuOpen = !langMenuOpen" @mouseleave="langMenuOpen = false">
                    <div class="lang__active">
                        <img :id="`headerLangActiveImg`" width="20" height="20" :src="activeLanguage.image" :alt="`language-${activeLanguage.name}`">
                        <img class="arrow" src="@/assets/img/svg/drop-down-arrow.svg" alt="drop-down-arrow">
                    </div>
                    <div class="lang__menu" v-if="langMenuOpen">
                        <div class="lang-item"
                            v-for="(language, index) in languages"
                            :key="`language-${index}`"
                            @click="saveLang(index)"
                        >
                            <img
                                v-if="language.abbrivations != activeLanguage.name"
                                :class="{'lang-item_incative-lang': language.abbrivations != activeLanguage.name}"
                                :id="`headerLangImg-${language.name}`"
                                width="20" height="20"
                                :src="language.image"
                                :alt="`lang-${language.name}`"
                            >
                        </div>
                    </div>
                </div> -->
        <div class="content">
          <span class="content__text">{{ headerTranslate.thisIsYourUsername.text }}, <span
              class="content__text--bold">{{
    headerTranslate.youCanChangeItLater.text }}</span></span>
          <div class="content__password" :style="{ marginBottom: '50px' }">
            <input-fields :value="userInfo.email || ''" read-only />
          </div>
          <span class="content__text">{{ headerTranslate.pleaseCreatePassword.text }}</span>
          <div class="content__password">
            <input-fields v-model.trim="$v.passwordForm.newPassword.$model"
              :class="{ 'input-error': $v.passwordForm.newPassword.$error }" :type="passwordTypes[0].type" :placeholder="headerTranslate.changePasswordInputPlaceholder1.text
    " />
            <img v-if="passwordTypes[0].type === 'text'" src="@/assets/img/svg/password-show.svg" alt="show-password"
              @click="changePasswordType(0)">
            <img v-if="passwordTypes[0].type === 'password'" src="@/assets/img/svg/password-hide.svg"
              alt="show-password" @click="changePasswordType(0)">
          </div>
          <div v-if="$v.passwordForm.newPassword.$error" class="error-text" style="padding: 6px; margin-top: -10px">
            {{ headerTranslate.changePasswordNewPasswordInputError.text }}
          </div>
          <div class="content__password">
            <input-fields v-model.trim="$v.passwordForm.confirmNewPassword.$model" :class="{
    'input-error': $v.passwordForm.confirmNewPassword.$error,
  }" :type="passwordTypes[1].type" :placeholder="headerTranslate.changePasswordInputPlaceholder2.text
    " />
            <img v-if="passwordTypes[1].type == 'text'" src="@/assets/img/svg/password-show.svg" alt="show-password"
              @click="changePasswordType(1)">
            <img v-if="passwordTypes[1].type == 'password'" src="@/assets/img/svg/password-hide.svg" alt="show-password"
              @click="changePasswordType(1)">
          </div>
          <div v-if="$v.passwordForm.confirmNewPassword.$error" class="error-text"
            style="padding: 6px; margin-top: -10px">
            {{
    headerTranslate.changePasswordConfirmNewPasswordInputError.text
  }}
          </div>
        </div>
        <div class="save" @click="savePassword()">
          <!-- <div class="info">{{headerTranslate.setupSupplierAgreement1.text}} <nuxt-link class="info__link" target="_blank" :to="localePath(`/supplier-agreement`)"> {{headerTranslate.setupSupplierAgreement2.text}} </nuxt-link></div> -->
          <Btn base :name="headerTranslate.saveBtn.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'
import notificationService from '@/services/notification'
import Btn from '@/components/controls/Btn.vue'
import RadioButton from '@/components/controls/RadioButton.vue'
import InputFields from '@/components/controls/Input-fields'

export default {
  props: ['isActive', 'headerTranslate'],
  data: () => ({
    activeLanguage: {},
    languages: [
      {
        name: 'English',
        is_checked: true,
        abbrivations: 'en'
      },
      {
        name: 'Español',
        is_checked: false,
        abbrivations: 'es'
      }
    ],
    lang: '',
    passwordTypes: [{ type: 'password' }, { type: 'password' }],
    passwordForm: {
      newPassword: '',
      confirmNewPassword: ''
    },
    langMenuOpen: false,
    submitResponse: ''
  }),
  async fetch() {
    const lang = this.$cookies.get('i18n_redirected')
    this.activeLanguage = {}
    let haveActiveLang = false
    this.languages[0].image = this.headerTranslate.langEUPicture.picture
    this.languages[1].image = this.headerTranslate.langESPicture.picture
    for (const i in this.languages) {
      if (this.languages[i].abbrivations == lang) {
        this.languages[i].is_checked = true
        haveActiveLang = true
        this.activeLanguage = {
          name: this.languages[i].abbrivations,
          image: this.languages[i].image
        }
      }
    }
    if (haveActiveLang == false) {
      this.languages[0].is_checked = true
    }
  },
  validations: {
    passwordForm: {
      newPassword: {
        required,
        password(value) {
          const minLength = value.length >= 6
          const containsLetter = /[A-Za-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsNoSpaces = /^\S*$/.test(value)
          return minLength && containsLetter && containsNumber && containsNoSpaces
        }
      },
      confirmNewPassword: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs('newPassword')
      }
    }
  },
  methods: {
    ...mapActions(['setUserIsLogin']),
    ...mapActions(['headerTranslation']),
    closeWindow() {
      const active = this.isActive
      this.$emit('close', active)
    },
    activeLang(index) {
      for (const i in this.languages) {
        this.languages[i].is_checked = false
      }
      this.languages[index].is_checked = true
      this.lang = this.languages[index].abbrivations
      this.activeLanguage.name = this.languages[index].abbrivations
      this.activeLanguage.image = this.languages[index].image
    },
    async saveLang(index) {
      for (const i in this.languages) {
        this.languages[i].is_checked = false
      }
      this.languages[index].is_checked = true
      this.lang = this.languages[index].abbrivations
      this.activeLanguage.name = this.languages[index].abbrivations
      this.activeLanguage.image = this.languages[index].image
      this.$i18n.setLocale(this.lang)
      this.headerTranslation()
    },
    changePasswordType(index) {
      this.passwordTypes[index].type =
        this.passwordTypes[index].type == 'password' ? 'text' : 'password'
    },
    checkValidate() {
      this.$v.passwordForm.$touch()
      if (!this.$v.passwordForm.$invalid) {
        return true
      }
    },
    async savePassword() {
      this.checkValidate()
      if (this.checkValidate()) {
        if (this.passwordForm) {
          const changePassForm = new FormData()
          changePassForm.append('new_password', this.passwordForm.newPassword)
          const changePass = await this.$axios.$put('/api/v1/change-password/?is_reset=true', changePassForm, { 'Content-Type': 'multipart/form-data' })
          if (typeof changePass.token === 'undefined') {
            notificationService.error({
              title: this.headerTranslate.submitDescription2.text,
              text: this.headerTranslate.submitDescriptionTryAgain.text
            })
          } else {
            notificationService.success({ title: this.headerTranslate.submitDescription1.text })
            this.$cookies.set('ulkid', changePass.token)
            this.$cookies.set('userlk', 'client')
            this.setUserIsLogin()
            this.$cookies.remove('needPass')
            this.closeWindow()
          }
        }
      } else {
        notificationService.error({
          title: this.headerTranslate.submitDescription2.text,
          text: this.headerTranslate.submitDescriptionTryAgain.text
        })
      }
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  components: {
    Btn,
    RadioButton,
    InputFields
  }
}
</script>

<style lang="scss">
.pop-up-setup-password {
  border-radius: 10px;
  position: relative;
  background: $white;
  padding: 65px 45px;
  max-width: 620px;

  &__content {
    .lang {
      margin-bottom: 20px;
      z-index: 2000;
      width: 50px;
      cursor: pointer;
      position: relative;

      &__active {
        display: flex;
        align-items: center;

        .arrow {
          width: 15px;
          height: 15px;
          margin-left: 5px;
        }
      }

      &__menu {
        position: absolute;
        left: -13px;
        padding: 12px 12px 2px 12px;
        background: $white;
        box-shadow: 0px 2px 8px rgba(30, 40, 67, 0.1);
        border-radius: 8px;

        .lang-item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          &_incative-lang {
            margin-bottom: 0;
          }
        }
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    .title {
      text-align: center;
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 28px;
      line-height: 33px;
    }

    .subtitle {
      text-align: center;
      margin-bottom: 65px;
    }

    .content {
      width: 80%;
      margin: 0 auto 30px;

      &__text {
        display: block;
        text-align: center;
        margin-bottom: 12px;

        &--bold {
          font-weight: 600;
        }
      }

      &__password {
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        position: relative;

        img {
          cursor: pointer;
          position: absolute;
          width: 24px;
          height: 24px;
          right: 14px;
          top: 11px;
          z-index: 10;
        }

        .input {
          width: 100%;
          border-radius: 12px;
        }
      }
    }

    .save {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {
        &_base {
          border: 2px solid $primary;
          border-radius: 4px;
          min-width: 102px;
          height: 48px;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          background: #ff664c;
          border-radius: 12px;
        }
      }
    }

    .info {
      text-align: center;
      font-size: 16px;
      line-height: 19px;

      &__link {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
</style>
