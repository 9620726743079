<template>
  <div v-if="meetingPoint !== null" class="preview__section">
    <div class="preview__title">
      {{ translations.textEndPoin.text }}
    </div>
    <p v-if="!meetingPoint.is_end_point" class="preview__text">{{ endPoint.address }}</p>
    <br v-if="endPoint != null && endPoint.description && !meetingPoint.is_end_point" />
    <p v-if="endPoint != null && endPoint.description && !meetingPoint.is_end_point" class="preview__text">
      {{ endPoint.description }}
    </p>
    <p v-else-if="meetingPoint.is_end_point" class="preview__text">{{ translations.textReturns.text }}</p>
  </div>
</template>

<script>
export default {
  name: 'MeetingPoint',
  props: {
    meetingPoint: { type: Object, default: () => {} },
    endPoint: { type: Object, default: () => {} },
    translations: { type: Object, default: () => ({}) }
  }
}
</script>

<style lang="scss"></style>
