var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VField",
    {
      attrs: {
        label: _vm.label,
        error: _vm.error,
        "error-message": _vm.errorMessage,
        description: _vm.description,
      },
    },
    [
      _c(
        "div",
        { staticClass: "v-radio-group" },
        [_vm._t("default", null, { on: _vm.on, attrs: _vm.attrs })],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }