import { format } from '@/utils/date';

export function checkAge(data, index) {
  let minAgeError = null;
  let maxAgeError = null;
  if (index !== 0) {
    if (Number(data[index].minAge) <= Number(data[index - 1].maxAge))
      minAgeError = true;
    else
      maxAgeError = false;

    if (Number(data[index].maxAge) <= Number(data[index].minAge))
      maxAgeError = true;
    else
      maxAgeError = false;
  } else if (Number(data[index].maxAge) <= Number(data[index].minAge)) {
    minAgeError = true;
    maxAgeError = true;
  } else {
    minAgeError = false;
    maxAgeError = false;
  }
  return { minAgeError, maxAgeError };
}

export function getDateSpan(oldSchedule, translations) {
  const startDate = new Date(oldSchedule.start_date).toDateString().slice(4);
  const endDate = translations.popupPersonThirdPageNoEndDate.text;
  if (oldSchedule.end_date <= '2098-03-12' && oldSchedule.end_date <= '2098-02-12')
    return `${startDate} ${new Date(oldSchedule.end_date).toDateString().slice(4)}`;
  return `${startDate} ${endDate}`;
}

export function isAdultOrSenior(member) {
  return member.id === 1 || member.id === 2;
}

export function getPriceFee(price, fee) {
  return (price - price * (fee / 100));
}

export const getAgeTieredGroupsDefault = ageCategoryId => ({
  minAge: null,
  minAgeError: false,
  maxAge: null,
  maxAgeError: false,
  price: '0',
  feePrice: '0',
  ageCategoryId
});

export function getAvailableDays(days) {
  let availableDays = '';
  let savedDate = null;
  for (const i in days) {
    if (days[i].is_active) {
      if (days[i].day === '7') {
        savedDate = '7';
      }
      availableDays += `${days[i].day},`;
    }
  }
  availableDays = availableDays.substring(0, availableDays.length - 1);
  if (savedDate != null) availableDays = availableDays !== savedDate ? `${availableDays},${savedDate}` : `${savedDate}`;

  return availableDays;
}
export function getAvailableOpeningTimes(openingHours, addTimes, openingScheduleTimes, anotherTimes) {
  if (openingHours) {
    return openingScheduleTimes.map(times =>
      `${format(new Date(times.startTime), 'HH:mm')},${format(new Date(times.endTime), 'HH:mm')}`
    ).join(',');
  }
  if (addTimes) {
    return anotherTimes.map(time => format(new Date(time.time), 'HH:mm:ss')).join(',');
  }

  return '';
}

export function mapMembers(members, isGroupPricing, maxTravelers) {
  return members.map(member => {
    let description = '';
    if (
      member.age_category.name === 'Infant' ||
      member.age_category.name === 'Child' ||
      member.age_category.name === 'Youth'
    ) {
      description = `Not available. If ${member.age_category.name} are allowed for free, set the retail price to €0.00.`;
    } else {
      description = 'Not available.';
    }
    return {
      ...member,
      is_active: isGroupPricing,
      price: '0',
      feePrice: '0',
      groupCount: maxTravelers.toString(),
      ageTieredGroups: [getAgeTieredGroupsDefault(member.age_category.id)],
      description
    };
  });
}
