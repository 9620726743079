var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "categories-form" },
    [
      _c("CategoriesSelector", {
        ref: "categoriesSelector",
        attrs: {
          categories: _vm.categories,
          tags: _vm.selectedTags,
          subcategories: _vm.selectedSubcategories,
          "category-id": _vm.selectedCategoryId,
          translations: _vm.translations,
        },
        on: { update: _vm.updateCategories },
      }),
      _vm._v(" "),
      _c(
        "VField",
        {
          staticClass: "categories-form__field",
          attrs: {
            label: _vm.translations.placeLabel.text,
            description: _vm.translations.placeDescription.text,
          },
        },
        [
          _c("GoogleAutocomplete", {
            attrs: {
              error: _vm.$v.cityPoint.$error,
              placeholder: _vm.translations.palcePlaceholder.text,
              options: { types: ["(cities)"] },
            },
            on: { "update:point": _vm.updateCity },
            model: {
              value: _vm.city,
              callback: function ($$v) {
                _vm.city = $$v
              },
              expression: "city",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("VBtn", {
        staticClass: "categories-form__button",
        attrs: { loading: _vm.loading, base: "", name: _vm.saveBtnText },
        on: { click: _vm.save },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }