<template>
  <div v-if="importantInfo && importantInfo !== ''" id="important-info" class="preview__section">
    <div class="preview__title">
      {{ translations.textImportant.text }}
    </div>
    <div class="preview__include">
      <div class="preview__dot preview__dot--orange" />
      <span class="preview__include-text">{{ importantInfo }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportantInfo',
  props: {
    importantInfo: { type: String, default: '' },
    translations: { type: Object, default: () => ({}) }
  }
}
</script>

<style lang="scss">
</style>
