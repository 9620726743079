<template>
  <div
    class="tour-price"
    :class="{ 'tour-price--sale': isSale, 'tour-price--small': small }"
  >
    <div class="tour-price__from">
      {{ fromText }}
    </div>
    <div class="tour-price__price">
      {{ formatCurrency(price, lang) }}
    </div>
    <div class="tour-price__group">
      {{ groupText }}
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "@/utils/utils";

export default {
  name: "TourPrice",
  props: {
    oldPrice: { type: String, default: "" },
    price: { type: String, default: "" },
    isGroupPricing: { type: Boolean, default: false },
    maxTravelers: { type: Number, default: 0 },
    currency: { type: Object, default: () => ({}) },
    lang: { type: String, default: "" },
    small: { type: Boolean, default: false },
    translations: { type: Object, default: () => ({}) },
  },

  methods: {
    formatCurrency,
  },
  fetch() {
    this.lang = this.$cookies.get("i18n_redirected");
  },
  computed: {
    isSale() {
      return Number(this.oldPrice) > 0;
    },

    fromText() {
      if (this.isSale) {
        return `${this.translations.textFrom.text} ${this.getPriceText(this.oldPrice)}`;
      }

      return this.translations.textFrom.text;
    },

    priceString() {
      return this.getPriceText(this.price);
    },

    groupText() {
      return this.isGroupPricing
        ? `${this.translations.textPerGroup.text} ${this.maxTravelers}`
        : this.translations.textPerPerson.text;
    },
  },
};
</script>

<style lang="scss">
.tour-price {
  position: relative;
  flex-shrink: 0;
  text-align: right;

  &__price {
    font-size: 22px;
    font-weight: 600;
    color: #1e2843;
    text-align: right;
    white-space: nowrap;
  }

  &__from {
    font-size: 12px;
    color: #565e72;
    white-space: nowrap;
    text-align: right;
  }

  &__group {
    font-size: 12px;
    font-weight: 500;
    color: #565e72;
    text-align: right;
    white-space: nowrap;

    &--sale {
      color: $orange;
    }
  }

  &--sale {
    .tour-price__price,
    .tour-price__group {
      color: $orange;
    }

    .tour-price__from {
      text-decoration: line-through;
    }
  }

  &--small {
    .tour-price__price {
      font-size: 18px;
    }
  }
}
</style>
