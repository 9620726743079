export const steps = {
  business: [
    {
      routeName: 'account-setup-public-info-company-info',
      nameTranslate: 'registrationCompanyInformation',
      name: 'companyInfo',
      type: 'public'
    },
    {
      routeName: 'account-setup-public-info-office-info',
      nameTranslate: 'registrationOfficeInformation',
      name: 'officeInfo',
      type: 'public'
    },
    {
      routeName: 'account-setup-public-info-company-image',
      nameTranslate: 'registrationCompanyImage',
      name: 'companyImage',
      type: 'public'
    },
    {
      routeName: 'account-setup-private-info-booking-management',
      nameTranslate: 'registrationBookingManagement',
      name: 'bookingManagement',
      type: 'private'
    },
    {
      routeName: 'account-setup-private-info-personal-info',
      nameTranslate: 'registrationPersonalInfo',
      name: 'personalInfo',
      type: 'private'
    },
    {
      routeName: 'account-setup-private-info-agreement-review',
      nameTranslate: 'registrationSupplierAgreement',
      name: 'supplierAgreement',
      type: 'private'
    }
  ],
  common: [
    {
      routeName: 'account-setup-supplier-type-declaration',
      name: 'declaration',
      nameTranslate: 'registrationDeclaration',
      type: 'supplier'
    }
  ],
  individual: [
    {
      routeName: 'account-setup-public-info-your-information',
      name: 'yourInformation',
      nameTranslate: 'registrationYourInformation',
      type: 'public'
    },
    {
      routeName: 'account-setup-public-info-office-information',
      name: 'officeInformation',
      nameTranslate: 'registrationOfficeInformation',
      type: 'public'
    },
    {
      routeName: 'account-setup-public-info-experience-type',
      name: 'experienceType',
      nameTranslate: 'registrationExperienceType',
      type: 'public'
    },
    {
      routeName: 'account-setup-public-info-your-expertise',
      name: 'yourExpertise',
      nameTranslate: 'registrationYourExpertise',
      type: 'public'
    },
    {
      routeName: 'account-setup-public-info-your-profile-picture',
      name: 'yourProfilePicture',
      nameTranslate: 'registrationYourProfilePicture',
      type: 'public'
    },
    {
      routeName: 'account-setup-private-info-personal-details',
      name: 'personalDetails',
      nameTranslate: 'registrationPersonalDetails',
      type: 'private'
    },
    {
      routeName: 'account-setup-private-info-address-information',
      name: 'addressInformation',
      nameTranslate: 'registrationAddressInformation',
      type: 'private'
    },
    {
      routeName: 'account-setup-private-info-agreement-review',
      nameTranslate: 'registrationSupplierAgreement',
      name: 'supplierAgreement',
      type: 'private'
    }
  ]
}

export const totalBusinessSteps = steps.business.length + steps.common.length
export const privateBusinessSteps = steps.business.filter(({ type }) => type === 'private')
export const publicBusinessSteps = steps.business.filter(({ type }) => type === 'public')

const totalIndividualSteps = steps.individual.length + steps.common.length
const privateIndividualSteps = steps.individual.filter(({ type }) => type === 'private')
const publicIndividualSteps = steps.individual.filter(({ type }) => type === 'public')

export const businessSteps = {
  public: publicBusinessSteps,
  private: privateBusinessSteps,
  total: totalBusinessSteps
}

export const individualSteps = {
  total: totalIndividualSteps,
  private: privateIndividualSteps,
  public: publicIndividualSteps
}

export const commonSteps = steps.common
