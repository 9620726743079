var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: {
        title: _vm.translations.acknowledgeBookingTitle.text,
        "dialog-classes": "booking-acknowledge",
      },
      model: {
        value: _vm.modelActive,
        callback: function ($$v) {
          _vm.modelActive = $$v
        },
        expression: "modelActive",
      },
    },
    [
      _c(
        "VField",
        {
          staticClass: "booking-acknowledge__field",
          attrs: {
            label: _vm.translations.confirmationNumberLabel.text,
            optional: _vm.translations.confirmationNumberOptional.text,
          },
        },
        [
          _c("VInput", {
            attrs: {
              placeholder: _vm.translations.confirmationNumberExample.text,
            },
            model: {
              value: _vm.acknowledge,
              callback: function ($$v) {
                _vm.acknowledge = $$v
              },
              expression: "acknowledge",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "booking-acknowledge__footer" },
        [
          _c(
            "VButton",
            {
              staticClass: "booking-acknowledge__button",
              attrs: { outline: "" },
              on: { click: _vm.close },
            },
            [_vm._v(_vm._s(_vm.translations.buttonCancel.text))]
          ),
          _vm._v(" "),
          _c(
            "VButton",
            {
              staticClass: "booking-acknowledge__button",
              attrs: { base: "" },
              on: { click: _vm.confirm },
            },
            [_vm._v(_vm._s(_vm.translations.btnConfirm.text))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }