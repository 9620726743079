<template>
  <div id="full-description" class="preview__section">
    <div class="preview__title">
      {{ translations.textDescription.text }}
    </div>
    <div :id="`CTFullDescription`" class="preview__text">
      <span :id="`CTFullDescriptionRows`" class="preview__text" :class="{ 'preview__text--desc': !showFullDescription }">{{
        description
      }}</span>
    </div>
    <template v-if="isDescriptionNotFull">
      <span v-if="!showFullDescription" class="preview__read-more" @click="showFullDescription = true">{{
        translations.textReadMore.text
      }}</span>
      <span v-else class="preview__read-more" @click="showFullDescription = false">{{ translations.textHide.text }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'FullDescription',
  props: {
    description: { type: String, default: '' },
    translations: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      showFullDescription: false,
      isDescriptionNotFull: false
    }
  },
  mounted() {
    if (process.browser) {
      const descriptionContainer = document.getElementById('CTFullDescriptionRows')
      const description = document.createElement('span')
      description.textContent = descriptionContainer.textContent
      descriptionContainer.innerHTML = ''
      descriptionContainer.appendChild(description)
      description.classList.add('text')
      if (description.getClientRects().length > 4) {
        this.isDescriptionNotFull = true
      }
    }
  }
}
</script>
