<template>
  <div class="activity-features-grid">
    <activity-icon icon-name="TourDuration" :title="getTourDurationTitle" :description="getTourDurationDescription" />
    <activity-icon
      icon-name="TourVouchers"
      :title="translations.textVouchers.text"
      :description="getTourVouchersDescription"
    />
    <activity-icon
      v-if="tour.covid_requirements.length || tour.covid_security.length"
      icon-name="Covid"
      :title="translations.textCovidP.text"
      :description="translations.textHealth.text"
    />
    <activity-icon
      v-if="tour.max_schedule_travelers < 9"
      icon-name="TourSmall"
      :title="translations.textSmallGroup.text"
      :description="translations.textLimited.text.replace('8', tour.max_schedule_travelers)"
    />
    <activity-icon
      v-if="tour.languages.length !== 0"
      icon-name="TourGuide"
      :title="translations.textTourGuide.text"
      :languages="tour.languages"
    />
    <activity-icon
      v-if="tour.cancellation_type !== 'no_refund'"
      icon-name="TourCancellation"
      :title="translations.textCancellation.text"
      :description="translations.textBeforeStart.text"
    />
    <activity-icon
      v-if="tour.pick_ups.length !== 0"
      icon-name="TourTransfer"
      :title="translations.textWithTransfer.text"
      :description="translations.textPickUp.text"
    />
    <activity-icon
      v-if="tour.is_skip_line"
      icon-name="TourSkipLine"
      :title="translations.textSTL.text"
      :description="translations.descriptionAcitivity.text"
    />
  </div>
</template>
<script>
import ActivityIcon from '@/components/preview/ActivityIcon.vue';
export default {
  name: 'ActivityTour',
  components: { ActivityIcon },
  props: {
    tour: { type: Object, default: () => {} },
    translations: { type: Object, default: () => {} },
    availabilityTimes: { type: Array, default: () => [] },
    flexibleDuration: { type: Boolean, default: false },
    tourFlexibleDuration1: { type: String, default: '' },
    tourFlexibleDuration2: { type: String, default: '' },
  },
  computed: {
    getTourDurationTitle() {
      if (this.availabilityTimes.length === 1) {
        if (!this.flexibleDuration) {
          return (
            this.translations.textDuration.text +
            (' ' + this.tour.duration_time > 59
              ? Math.floor(this.tour.duration_time / 60) + ' ' + this.translations.timeH.text
              : '') +
            ' ' +
            (this.tour.duration_time % 60) +
            ' ' +
            this.translations.timeMin.text
          );
        } else {
          return (
            this.translations.textDuration.text +
            ' ' +
            this.tourFlexibleDuration1 +
            ' - ' +
            this.tourFlexibleDuration2 +
            ' ' +
            this.tour.duration_time_unit
          );
        }
      } else if (!this.flexibleDuration) {
        return (
          this.translations.textDuration.text +
          (this.tour.duration_time > 59
            ? ' ' + Math.floor(this.tour.duration_time / 60) + ' ' + this.translations.timeH.text
            : '') +
          ' ' +
          (this.tour.duration_time % 60) +
          ' ' +
          this.translations.timeMin.text
        );
      } else {
        return (
          this.translations.textDuration.text +
          ' ' +
          this.tourFlexibleDuration1 +
          ' - ' +
          this.tourFlexibleDuration2 +
          ' ' +
          this.tour.duration_time_unit
        );
      }
    },
    getTourDurationDescription() {
      if (this.availabilityTimes.length === 1) {
        if (this.tour.start_time.split('-').length === 1) {
          return this.translations.textStartTime.text + ' ' + this.tour.start_time;
        } else {
          return this.translations.textOpeningHours.text + this.tour.start_time;
        }
      } else {
        return this.translations.textVouchers.text;
      }
    },
    getTourVouchersDescription() {
      return this.tour.ticket_type === 'paper_and_electronic'
        ? this.translations.textPaper.text
        : this.translations.textOnlyPaper.text;
    },
  },
};
</script>

<style lang="scss">
.activity-features-grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: min-content min-content;
  row-gap: 25px;
  justify-items: center;
  align-items: start;
  @media (max-width: 768px) {
    display: block;
  }
}
</style>
