import { businessSteps, individualSteps, commonSteps, steps } from '@/constants/profileSteps'

export function getStep(user) {
  const { public: publicSteps, private: privateSteps, enabled } = getCurrentSteps(user)
  const allSteps = [...publicSteps, ...privateSteps]
  let lastEnabledStep = null

  for (const step of allSteps) {
    if (enabled[step.name]) {
      lastEnabledStep = step
    } else {
      break
    }
  }

  return lastEnabledStep
}

export function getNextStep(currentStep, user) {
  const currentSteps = getCurrentSteps(user)
  const allStep = [...commonSteps, ...currentSteps.public, ...currentSteps.private]

  return allStep[getStepIndex(currentStep, user) + 1]
}

export function getStepIndex(step, user) {
  const currentSteps = getCurrentSteps(user)
  return [...commonSteps, ...currentSteps.public, ...currentSteps.private].findIndex(({ name }) => name === step.name)
}

export function getCurrentSteps(user) {
  const isEnableIndividualStep = getEnableIndividualStep(user)
  const isEnableBussinesStep = getEnableBussinesStep(user)


  if (user.is_seller_company) {
    return { ...businessSteps, enabled: isEnableBussinesStep }
  } else {
    return { ...individualSteps, enabled: isEnableIndividualStep }
  }
}

export function getFilledIndividualSteps(user) {
  return {
    declaration: user.is_seller_company !== null,
    yourInformation:
      Boolean(user.seller_full &&
        user.company_name &&
        user.seller_tagline &&
        user.bio),
    officeInformation:
      Boolean(user.office_country && user.office_city && user.office_address_code && user.office_address),
    experienceType: Boolean(user.category && user.subcategory && user.subcategory.length > 0 && user.seller_place),
    yourExpertise: Boolean(user.is_seller_professional || user.job_title),
    yourProfilePicture: Boolean(user.company_logo),
    personalDetails: Boolean(user.birth && user.personal_phone),
    addressInformation:
      Boolean(user.seller_country && user.seller_city && user.seller_address && user.seller_address_code)
  }
}

export function getFilledBussinesSteps(user) {
  return {
    declaration: user.is_seller_company !== null,
    companyInfo:
      Boolean(user.company_name &&
        user.company_trading_name &&
        user.company_site_link &&
        user.contact_email &&
        user.contact_phone &&
        user.contact_phone_code),
    officeInfo:
      Boolean(user.office_country && user.office_address && user.office_city && user.office_address_code),
    companyImage: Boolean(user.company_logo && user.company_info),
    bookingManagement: Boolean(user.reservation_system_type),
    personalInfo:
      Boolean(user.personal_phone_code &&
        user.personal_phone &&
        user.contact_first_name &&
        user.contact_last_name &&
        user.job_title),
    supplierAgreement: false
  }
}

export function getEnableBussinesStep(user) {
  const isFilledBussinesSteps = getFilledBussinesSteps(user)
  return {
    declaration: true,
    companyInfo: isFilledBussinesSteps.declaration,
    officeInfo: isFilledBussinesSteps.companyInfo,
    companyImage: isFilledBussinesSteps.officeInfo,
    bookingManagement: isFilledBussinesSteps.companyImage,
    personalInfo: isFilledBussinesSteps.bookingManagement,
    supplierAgreement: isFilledBussinesSteps.personalInfo
  }
}

export function getEnableIndividualStep(user) {
  if (!user) {
    return {}
  }

  const isFilledIndividualSteps = getFilledIndividualSteps(user)
  return {
    declaration: true,
    yourInformation: isFilledIndividualSteps.declaration,
    officeInformation: isFilledIndividualSteps.yourInformation,
    experienceType: isFilledIndividualSteps.officeInformation,
    yourExpertise: isFilledIndividualSteps.experienceType,
    yourProfilePicture: isFilledIndividualSteps.yourExpertise,
    personalDetails: isFilledIndividualSteps.yourProfilePicture,
    addressInformation: isFilledIndividualSteps.personalDetails,
    supplierAgreement: isFilledIndividualSteps.addressInformation
  }
}

export function getStepByRouteName(routeName) {
  const allSteps = [...steps.common, ...steps.business, ...steps.individual]
  return allSteps.find(step => routeName === step.routeName)
}
