var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.importantInfo && _vm.importantInfo !== ""
    ? _c(
        "div",
        { staticClass: "preview__section", attrs: { id: "important-info" } },
        [
          _c("div", { staticClass: "preview__title" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.translations.textImportant.text) + "\n  "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "preview__include" }, [
            _c("div", { staticClass: "preview__dot preview__dot--orange" }),
            _vm._v(" "),
            _c("span", { staticClass: "preview__include-text" }, [
              _vm._v(_vm._s(_vm.importantInfo)),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }