var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "your-expertise-form" },
    [
      _c("VRadioGroup", {
        staticClass: "your-expertise-form__field",
        attrs: {
          label: "Are you a professional tour guide?",
          name: "isProfessional",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "VRadioButton",
                  _vm._g(
                    _vm._b(
                      { attrs: { value: true } },
                      "VRadioButton",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [_vm._v(" Yes ")]
                ),
                _vm._v(" "),
                _c(
                  "VRadioButton",
                  _vm._g(
                    _vm._b(
                      { attrs: { value: false } },
                      "VRadioButton",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [_vm._v(" No ")]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.isProfessional,
          callback: function ($$v) {
            _vm.isProfessional = $$v
          },
          expression: "isProfessional",
        },
      }),
      _vm._v(" "),
      _vm.isProfessional
        ? _c("VRadioGroup", {
            staticClass: "your-expertise-form__field",
            attrs: {
              name: "hasLicense",
              label: "Do you have a tour guide license?",
              "sub-label":
                "In some places, it is mandatory for tour guides to have a licence. It is your responsibility to comply with all the laws and regulations. ",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "VRadioButton",
                        _vm._g(
                          _vm._b(
                            { attrs: { value: true } },
                            "VRadioButton",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v(" Yes ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "VRadioButton",
                        _vm._g(
                          _vm._b(
                            { attrs: { value: false } },
                            "VRadioButton",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v(" No ")]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2117523412
            ),
            model: {
              value: _vm.hasLicense,
              callback: function ($$v) {
                _vm.hasLicense = $$v
              },
              expression: "hasLicense",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("VInput", {
        staticClass: "your-expertise-form__field",
        attrs: {
          error: _vm.$v.profession.$error,
          label: "What is your profession?",
          optional: _vm.isProfessional ? "optional" : "",
          "sub-label":
            "Sharing your profession with travellers is a good way to tell them more about yourself. ",
          placeholder: "Your profession",
          "error-message": "Please provide us with your profession",
        },
        model: {
          value: _vm.$v.profession.$model,
          callback: function ($$v) {
            _vm.$set(_vm.$v.profession, "$model", $$v)
          },
          expression: "$v.profession.$model",
        },
      }),
      _vm._v(" "),
      _c("VBtn", {
        staticClass: "your-expertise-form__button",
        attrs: { loading: _vm.loading, base: "", name: _vm.saveBtnText },
        on: { click: _vm.save },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }